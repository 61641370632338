%container {
    max-width: calc(100vw - 100px);
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    padding: 1rem;
    overflow: hidden;
}
.error-container {
    @extend %container;
    border-color: #FFA39E;
    background-color: #FFF1F0;
}
.success-container {
    @extend %container;
    border-color: green;
    background-color: lightgreen;
}
.warning-container {
    @extend %container;
    background-color: lightyellow;
    border-color: var(--ztn-yellow);
}

%text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    background-color: inherit;
}
.error-text {
    @extend %text;
    color: var(--ztn-dark);
}
.success-text {
    @extend %text;
    color: green;
}
.warning-text {
    @extend %text;
    color: var(--ztn-yellow);
}

%title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
}
.error-title {
    @extend %title;
    color: var(--ztn-error);
}
.success-title {
    @extend %title;
    color: green;
}
.warning-title {
    @extend %title;
    color: yellow;
}

.header {
    background-color: inherit;
    box-shadow: none;
}
.icon-container,
.button-container {
    float: left;
}

.content {
    width: 92%;
    margin: 0px auto;
}
.icon-container,
.button-container {
    width: 4%;
}
.button-container {
    display: flex;
    justify-content: right;
}

.button-container img {
    width: 18px;
    filter: invert(68%) sepia(0%) saturate(314%) hue-rotate(182deg) brightness(83%) contrast(78%);
}
.button-container img:hover {
    cursor: pointer;
}
