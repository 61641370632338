:root {
  --margin-b-xs: 0.2rem;
  --margin-b-sm: 0.5rem;
  --margin-b-md: 0.75rem;
  --margin-b-rg: 1rem;
  --margin-b-lg: 2rem;
  --margin-b-xl: 3rem;
  --margin-l-xs: 0.2rem;
  --margin-l-sm: 0.5rem;
  --margin-l-md: 0.75rem;
  --margin-l-rg: 1rem;
  --margin-l-lg: 2rem;
  --margin-l-xl: 3rem;
  --margin-t-xs: 0.2rem;
  --margin-t-sm: 0.5rem;
  --margin-t-md: 0.75rem;
  --margin-t-rg: 1rem;
  --margin-t-lg: 2rem;
  --margin-t-xl: 3rem;
  --margin-r-rg: 1rem;
}

.margin-b-xs {
  margin-bottom: var(--margin-b-xs);
}
.margin-b-sm {
  margin-bottom: var(--margin-b-sm);
}
.margin-b-rg {
  margin-bottom: var(--margin-b-rg);
}
.margin-b-lg {
  margin-bottom: var(--margin-b-lg);
}
.margin-b-xl {
  margin-bottom: var(--margin-b-xl);
}

.margin-t-xs {
  margin-top: var(--margin-t-xs);
}
.margin-t-sm {
  margin-top: var(--margin-t-sm);
}
.margin-t-rg {
  margin-top: var(--margin-t-rg);
}
.margin-t-lg {
  margin-top: var(--margin-t-lg);
}
.margin-t-xl {
  margin-top: var(--margin-t-xl);
}

.margin-l-sm {
  margin-left: 0.5rem;
}
.margin-l-md {
  margin-left: 0.75rem;
}
.margin-l-rg {
  margin-left: 1rem;
}

.margin-r-rg {
  margin-right: var(--margin-r-rg);
}
