:root {
  --default-padding: 15px;
  --padding-b-xs: 0.2rem;
  --padding-b-sm: 0.5rem;
  --padding-b-md: 0.75rem;
  --padding-b-rg: 1rem;
  --padding-b-lg: 2rem;
  --padding-b-xl: 3rem;
  --padding-l-xs: 0.2rem;
  --padding-l-sm: 0.5rem;
  --padding-l-md: 0.75rem;
  --padding-l-rg: 1rem;
  --padding-l-lg: 2rem;
  --padding-l-xl: 3rem;
  --padding-t-xs: 0.2rem;
  --padding-t-sm: 0.5rem;
  --padding-t-md: 0.75rem;
  --padding-t-rg: 1rem;
  --padding-t-lg: 2rem;
  --padding-t-xl: 3rem;
  --padding-r-xs: 0.2rem;
  --padding-r-sm: 0.5rem;
  --padding-r-md: 0.75rem;
  --padding-r-rg: 1rem;
  --padding-r-lg: 2rem;
  --padding-r-xl: 3rem;
}

.padding-b-xs {
  padding-bottom: var(--padding-b-xs);
}
.padding-b-sm {
  padding-bottom: var(--padding-b-sm);
}
.padding-b-rg {
  padding-bottom: var(--padding-b-rg);
}
.padding-b-lg {
  padding-bottom: var(--padding-b-lg);
}
.padding-b-xl {
  padding-bottom: var(--padding-b-xl);
}

.padding-t-xs {
  padding-top: var(--padding-t-xs);
}
.padding-t-sm {
  padding-top: var(--padding-t-sm);
}
.padding-t-rg {
  padding-top: var(--padding-t-rg);
}
.padding-t-lg {
  padding-top: var(--padding-t-lg);
}
.padding-t-xl {
  padding-top: var(--padding-t-xl);
}

.padding-r-xs {
  padding-right: var(--padding-r-xs);
}
.padding-r-sm {
  padding-right: var(--padding-r-sm);
}
.padding-r-rg {
  padding-right: var(--padding-r-rg);
}
.padding-r-lg {
  padding-right: var(--padding-r-lg);
}
.padding-r-xl {
  padding-right: var(--padding-r-xl);
}

.padding-l-xs {
  padding-left: var(--padding-l-xs);
}
.padding-l-sm {
  padding-left: var(--padding-l-sm);
}
.padding-l-rg {
  padding-left: var(--padding-l-rg);
}
.padding-l-lg {
  padding-left: var(--padding-l-lg);
}
.padding-l-xl {
  padding-left: var(--padding-l-xl);
}
