a {
  color: var(--ztn-color-primary);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  background-color: var(--ztn-theme-hover);
}

.textColoredLinks a,
.textColoredLinks a:hover {
  color: inherit;
}

.activeLink {
  background-color: #1122;
}

.spreadLink a {
  height: 100%;
  width: 100%;
}

.sizeFill a {
  max-height: 100%;
  max-width: 100%;
}
