@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'colors.css';
@import 'margins.css';
@import 'paddings.css';
@import 'icons.css';
@import 'fonts.css';
@import 'shadows.css';
@import 'links.css';

:root {
  --ztn-header-height: 3.8rem;
}

* {
  box-sizing: border-box;
  transition: .0825s;
  transition-timing-function: ease-out;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
}

*:focus {
    outline: none !important;
}
*:focus-within {
    outline: none !important;
}



html, body, #__next {
  height: 100%;
  min-height: 100%;
}

html, body {
  font-family: var(--ztn-font-family);
  /* font-size: 1.1rem; */
  line-height: 1.5;
  color: var(--ztn-color-primary);
  background-color: var(--ztn-color-bg-primary);
  min-width: 460px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 42px;
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28.13px;
  text-transform: uppercase;
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
  text-transform: uppercase;
}

.link {
  color: var(--ztn-purple);
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}
.link:hover {
  text-decoration: none;
  background-color: var(--ztn-theme-hover);
  border-radius: 5px;
}

header {
  padding: 0 auto;
  background-color: var(--ztn-color-bg-secondary);
  max-height: var(--ztn-header-height);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 1.3rem;
}

.stickyTop {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px !important;
}

.stickyMenu {
  position: sticky !important;
  top: var(--ztn-header-height);
}

.positionFixed {
  position: fixed;
}

footer {
  font-size: .8rem;
  color: hsl(246, 8%, 51%);
}

.roundedFull,
.roundedPill {
  border-radius: 9999px;
}

.roundedSmall {
  border-radius: 0.5rem;
}

.hoverRoundedFull:hover {
  border-radius: 50%;
}


.highlightLeft {
  border-left: solid 3px #dde;
}

.highlightBottom {
  border-bottom: solid 3px #dde;
}

.sizeFill {
  max-width: 100%;
  max-height: 100%;
}

.fillHeight {
  height: 100%;
}

.sizeAuto {
  width: auto;
  height: auto;
  display: inline-block;
  overflow: hidden;
}

.alignVerticalBaseline {
  align-content: baseline;
}

.displayBlock {
  display: block;
}

.displayInline {
  display: inline;
}

.hoverShadow:hover,
.hoverShadow:focus-within {
  box-shadow:  0px 1px 5px -2px #1158;
    /* , inset 0px 0px 0px 3px rgba(119, 151, 255, 50%) ; */
  background-color: #55558809;
  /* transition: .15s; */
}

.lightbox {
  z-index: 6;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 90%;
  height: 85vh;
  padding: 25px;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}


.projectCardGrid {
  display: grid;
  margin: 2rem 0;
  grid-template-columns: repeat(auto-fit, minmax(228px,374px));
  gap: 1.5rem;
}

.projectsHeader {
  display: grid;
  grid-template-columns: repeat(auto-fit);
}

@media(min-width: 640px) and (max-width: 868px) {
  .projectCardGrid {
    justify-content: center;
  }
}

@media(max-width: 639px) {
  .projectCardGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .headerContainer {
    flex-direction: column;
  }

  .projectsHeader h2{
    margin-bottom: 1rem;
  }
}

/* title */
.projectsTitle {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;

  font-size: 22px;
  line-height: 25.78px;

  color: #6A2BE1;

}

/* Create New Project */

.projectButtonIcon {
  text-align: center;
  font-size: 3rem;

  margin-right: 1rem;
}

.projectButtonText {
  padding: .5rem;
  color: #FFFFFF;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1rem;

  text-align: center;
}

.projectButton {
  vertical-align: middle;
  align-content: baseline;

  border: 0.5px solid #6A2BE1;
  background: rgba(106, 43, 225, 0.6);

  width: 20rem;
  height: 4rem;

  /* ---display: block; */
  display: inline-block;

  border-radius: 0.375rem;
}

.testCardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 35rem));
  gap: 0;
}

.testCard {
  display: block;
  max-width: 35rem;
}


.sidebar {
  flex: 0;
  z-index: 9998;
  box-shadow: 0px 2px 3px 1px #C5C5C5;
  border-bottom: 1px solid #3353;
  border-left: 3px solid rgba(155, 116, 173, 0.521);
  position: relative;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    min-width: 100%;
    /* transition: none; */
    height: 100%;
    min-height: unset;

  }
}

.flexFillViewHeight {
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  min-height: 100vh;
}

.gap-1 {
  gap: .5em; /* too recent, only widely supported summer 2021 */
}
.flexGap-1 {
  --gap: .5em;
  margin: calc(-1.3 * var(--gap))  0 0 calc(-1 * var(--gap));
}
.flexGap-1 > * {
  margin: var(--gap) 0 0 var(--gap);
}

.flexAlignTop {
  align-items: flex-start;
}
.flexAlignCenter {
  align-items: center;
}

.flexGrow {
  flex-grow: 1;
}

.flexStretch {
  align-content: stretch;
}
.flexStart {
  align-content: flex-start;
}

@media only screen and (max-width: 600px) {
  .smFlexStretch {
    align-content: stretch;
  }
  .smFlexStart {
    align-content: flex-start;
  }
}

.flexNoWrap {
  flex-wrap: nowrap;
}



.purple {
  color: var(--ztn-theme-primary);
}
.purple:hover {
  color: var(--ztn-theme-primary);
}

.mainNavMenu {
  font-size: 1.05rem;
  font-family: Rubik, var(--ztn-font-family);
  position: relative;
}




.listNoBullet * {
  list-style: none;
}

.cursorNone {
  cursor: default;
}

.cursorPointer {
  cursor: pointer;
}

.hiddenHeight ul div {
  display: inline-block;
  width: auto;
  height: 0;
}

.flexJustifyFull{
  justify-content: space-between;
}

.flexJustifyEnd{
  justify-content: flex-end;
}

.alignRight {
  text-align: right;
}

.alignLeft {
  text-align: left;
}

.activeHighlight,
.hoverHighlight:hover,
.hoverHighlight:focus,
.hoverHighlight:focus-within {
  background-color: #ccf2;
}

.activeDarken,
.hoverDarken:hover,
.hoverDarken:focus,
.hoverDarken:focus-within {
  background-color: #dde6;
}

.lineHeight2{
  line-height: 2em;
}

.valignSub {
  vertical-align: sub;
}

.menuIcon {
  color: var(--ztn-color-bg-primary);
}

.displayNone {
  display: none;
}

.displayHidden {
  visibility: hidden;
  opacity: 0;
}

.opacity50,
.hoverOpacity50:hover {
  opacity: 50%;
}

.opacity90,
.hoverOpacity90:hover {
  opacity: 90%;
}

.opacity100,
.hoverOpacity100:hover {
  opacity: 100%;
}

.touchFriendlyHeight {
  min-height: 42px !important;
  vertical-align: middle;
}

.displayFlex,
.displayFlexRow {
  display: flex;
}

.displayFlexCol {
  display: flex;
  flex-direction: column;
}
.iconBigger {
  font-size: 1.5rem;
}

.size50 {
  height: 100px;
  width: 100px;
}

.badgeDark {
  color: var(--ztn-color-bg-secondary);
  background-color: var(--ztn-color-highlight-2);
  font-weight: 500;
}

.badgeLight {
  color: var(--ztn-color-bg-secondary);
  background-color: var(--ztn-color-highlight-3);
  font-weight: 500;
}

.badgeBell {
  color: #fff;
  background-color: var(--ztn-purple);
  font-weight: 500;
  position: relative;
  right: 9px;
  top: -9px;
  user-select: none;
}

.subtleMonochrome {
  background-color: var(--ztn-color-secondary);
}

.tableDiv{
  border: 1px solid #E4E4E4;
  border-radius: 4px;
}

.tableDefaultRow{
  background: #FFFFFF;
  margin: 5px;
}

.tableHoverRow{
  background: #F3EFFF;
}

.tableSelectedRow{
  background: #621ee0;
}

.tableHeader{
  background: #ECECEC 30%;
  margin: 5px;
  border-radius: 4px;
}

.tableContainer{
  background: #FFFFFF;
  border: #E4E4E4 1px;
}

.tableRowText{
  /* font-family: Roboto; needs to be imported? */
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;

  color: #29000A;
}

.tableHeaderText{
  /* font-family: Roboto; needs to be imported? */
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;

  color: #717171;
}

.titleText{
  /* font-family: Roboto; needs to be imported? */
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 42px;

  color: #6A2BE1;
}

.shadedBackground{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 5;
  background: var(--ztn-color-bg-shade);
}


ul, li {
  list-style: none;
}

/* React Bootstrap Tab */
.tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px !important;
  color: #6A2BE1 !important;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0px !important;
}
.tab__content {
  padding: 3.25rem 1.25rem;
  background: var(--ztn-color-card-2nd-top);
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
  border-radius: 0px;
  border-left: 1.5px solid #E7E7E7;
  border-right: 0.5px solid #E7E7E7;
  border-bottom: 0.5px solid #E7E7E7;
}

.rotate180 {
    rotate: 180deg;
}

.scrollbar-gutter-stable {
  scrollbar-gutter: stable;
}


input[type=range] {
  height: 10px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #AC51B5;
  /* border-radius: 25px; */
  border: 0px solid #000101;
  border-radius: 4px;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 10px;
  width: 39px;
  /* border-radius: 7px; */
  background: darkorchid;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -2.5px;
  border-radius: 4px;
}
input[type=range]::-webkit-slider-thumb:hover {
  background: purple;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #AC51B5;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 13px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: rgb(109 40 217);
  /* border-radius: 25px; */
  border: 0px solid #000101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 2px solid rgb(109 40 217);
  height: 20px;
  width: 39px;
  /* border-radius: 7px; */
  background: white;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 13px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #AC51B5;
  border: 0px solid #000101;
  /* border-radius: 50px; */
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #AC51B5;
  border: 0px solid #000101;
  /* border-radius: 50px; */
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 39px;
  /* border-radius: 7px; */
  background: #65001C;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #AC51B5;
}
input[type=range]:focus::-ms-fill-upper {
  background: #AC51B5;
}
