.stickyHeader {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    height: 72px;
}

.header {
    display: flex;
    align-items: center;
    position: relative;
}


.icon {
    height: 61px;
    padding: 10px;
    min-width: 68px;
}

.headerText {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 100px;
}

.headerText p {
    position: absolute;
    font-size: 0.6rem;
    top: calc(100% - 1.1rem);
    left: 0;
    font-weight: bold;
    color: var(--ztn-purple);

}

.headerText h1 {
    font-weight: 100;
    font-size: 1.5rem;
    color: rgb(66, 9, 172);
    /* margin-top: auto; */

}

.settingsContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.iconContainer {
    margin: 0 5px;
    display: grid;
    place-items: center;
}
