:root {
  --ztn-font-family: Roboto, Rubik, Inter, Cairo, ui-sans-serif, system-ui, sans-serif;
  --ztn-font-primary: Comfortaa;
}

.font400 {
  font-weight: 400;
}

.font500 {
  font-weight: 500;
}

.fontBold {
  font-weight: 600;
}

.fontSmall {
  font-size:90%;
}

.fontUppercase{
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.fontSubtle {
  font-weight:normal;
  color:  hsl(240, 10%, 60%);
}

.fontSize90 {
  font-size: 90%;
}

.fontSize70 {
  font-size: 70%;
}

.fontSize50 {
  font-size: 50%;
}

.fontFadeLight{
  color: #eef6;
}

.fontFadeDark{
  color: #3358;
}

.fontDisplay {
  font-weight: 600;
  letter-spacing: .1rem;
  text-transform: uppercase;
}

.text-sm {
  font-size: 0.75rem;
}
.text-md {
  font-size: 0.875rem;
}
.text-reg {
  font-size: 1rem;
}
.txt-lg {
  font-size: 1.5rem;
}
.text-xl {
  font-size: 2rem;
}

.text-underline {
  text-decoration: underline;
}
