.container {
    margin: 0 auto;
    max-width: 600px;
    padding: 1rem;
}

.header {
    display: block;
    background-color: inherit;
    box-shadow: none;
    max-height: unset;
    margin-bottom: 1rem;

    h1 {
        margin: 0;
        line-height: unset;
        color: var(--ztn-notification-purple);
        font-weight: bold;
        text-transform: uppercase;
        font-size: 3rem;
    }

    img {
        width: 40%;
        min-width: 200px;
        margin-bottom: 2rem;
        filter: invert(71%) sepia(6%) saturate(2438%) hue-rotate(216deg) brightness(78%) contrast(87%);
    }
}

.message {
    margin-bottom: 1rem;
    text-align: justify;
    color: #827895;
}

.actions {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
}
.view-details,
.link {
    color: var(--ztn-purple);
}
.link {
}
.view-details {
    user-select: none;
    cursor: pointer;
    margin-right: auto;
    display: flex;
    align-items: center;

    img {
        margin-left: .2rem;
        width: 20px;
        filter: var(--ztn-filter-purple);
        transition: none;
    }
}
.details {
    font-family: monospace;
    max-height: 200px;
    overflow-y: scroll;
    margin: 0;

    p {
        margin: 0;
    }
}

.rotate180 {
    rotate: 180deg;
}
