.btn-primary {
	background-color: var(--ztn-theme-primary) !important;
	border: none;
}

.btn-primary:hover {
	background-color: var(--ztn-theme-hover);
	border: none;
}

.btn-primary:active {
	background-color: var(--ztn-theme-primary);
	border: none;
}

.btn-primary:visited {
	background-color: var(--ztn-theme-primary);
	border: none;
}

.btn-primary:focus {
	border: none !important;
	box-shadow: none !important;
}

.btn-outline-primary {
	color: var(--ztn-theme-primary);
	border-color: var(--ztn-theme-primary);
}

.btn-outline-primary:hover {
	background-color: var(--ztn-theme-primary);
	border-color: var(--ztn-theme-primary);
}

.dropdown-item:focus, .dropdown-item:hover {
    color: var(--ztn-color-primary);
    text-decoration: none;
    background-color: var(--ztn-theme-hover);
}

.progress {
	background-color: var(--ztn-theme-primary) !important;
	height: 12px !important;
	padding: 3px 3px !important;
}

.progress-bar {
	background-color: white;
	height: 6px !important;
}

div[class^="Embedded_dropdown-menu__"].show {
	transform: translate(0, 38px) !important;
}

/* Remove the (!) icon within invalid input fields */
.form-control.is-invalid {
	background-image: none;
}

div.invalid-feedback {
	font-size: 14px;
}

.form-group {
	margin: 0;
}

/* Checkbox */
div.form-check {
	padding-left: 1.7rem;
}
.form-check-input {
	top: 0.1rem;
	left: 0;
	margin: 0;
	width: 16px;
	height: 16px;
	cursor: pointer;
}
.form-check-label {
	cursor: pointer;
	user-select: none;
}

/* Prevents checkbox label from turning red when invalid */
.form-check-input.is-invalid ~ .form-check-label {
	color: #827895;
}
/* Material UI Lib */
/* Targets the label of a checked radio button. */
span.Mui-checked ~ span {
	color: var(--ztn-purple);
}
