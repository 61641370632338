.link {
  color: var(--ztn-theme-primary);
}

.link:hover {
  color: var(--ztn-theme-primary);
}

.btn {
	display: flex;
	align-items: center;
	margin-left: 15px;
	color: var(--ztn-theme-primary) !important;
	background-color: var(--ztn-color-bg-secondary) !important;
	cursor: pointer;
	border: none !important;
}

.btn svg {
	font-size: 1.3rem;
}

.btn::after {
	display: none;
}

.btn:hover {
	background-color: var(--ztn-theme-hover) !important;
}

.name {
	padding-left: 8px;
	font-size: 0.8rem;
}

.name svg {
	font-size: 1rem;
}

.wrapper {
	display: flex;
	align-items: center;
}

.icon {
	margin-bottom: 3px;
}
.manage {
	color: var(--ztn-color-secondary);
	margin-left: 7px;
}

.gear {
	margin-bottom: 3px;


}


.dirHeadersContainer {
	display: flex;
	font-size: 0.8rem;
	margin-left: -7px;
	color: var(--ztn-purple)

}

.dirHeadersContainer a {
	padding: 2px;
	color: var(--ztn-purple);
	text-transform: capitalize;
}
