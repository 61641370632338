$primary: var(--ztn-theme-primary);
@use '@carbon/react';

.cds--pagination {
  border: 1px solid #dddddd66;
  box-shadow: 0 0 3px 1px #dddddd66;
  background-color: #ffffff77;
}
// Note I don't know why this style is broken in the pagination component, this is a hack to fix it
.cds--select--inline .cds--select__arrow {
  right: 0.2rem;
}
