.input-text {
  color: #827895;
  font-weight: 500;
  font-size: 1rem;
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 0.9rem;
}

.form__email,
.form__password,
.actions__row1 {
  margin-bottom: 3rem;
}

.input__email {
  font-size: 1rem;
  padding: 1rem 1.2rem;
  height: 56px;
}

.card {
  margin: 9rem auto 0 auto;
}

.actions__row2 {
  margin-top: 3rem;
  display: flex;
  gap: 1rem;
}

.checkbox-input {
  border: 1px solid lightgray;
}
