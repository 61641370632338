:root {
  --ztn-color-primary: #335;
  --ztn-color-secondary: #555;
  --ztn-color-highlight: #9b3c4e;
  --ztn-color-highlight-2: #d15276;
  --ztn-color-highlight-3: #a968b8;
  --ztn-color-bg-primary: #F6F6F6;
  --ztn-color-bg-secondary: #f9faff;
  --ztn-color-card-top: #ffffff;
  --ztn-color-card-2nd-top: #ffffff;
  --ztn-color-bg-shade: rgba(61, 61, 61, 0.2);
  --ztn-color-bg-tertiary: #F0F0F0;
  --ztn-purple: rgb(106, 43, 225, 1);
  --ztn-purple-80: hsl(261,75,53,0.7);
  --ztn-purple-grey: #DCD7E5;
  --ztn-light-purple: #F5EFFF;
  --ztn-medium-purple: #E6DFFF;
  --ztn-notification-purple: #9884BE;
  --ztn-burgundy: #800020;
  --ztn-orange: #C1280C;
  --ztn-yellow: #FFC000;
  --ztn-black: #000;
  --ztn-theme-primary: var(--ztn-purple);
  --ztn-theme-hover: var(--ztn-medium-purple);
  --ztn-error: #FFA39E;
  --ztn-filter-purple: invert(21%) sepia(66%) saturate(4135%) hue-rotate(255deg) brightness(86%) contrast(106%);
}

.error {
  color: red;
}

.filter-theme-primary {
  filter: invert(18%) sepia(93%) saturate(3483%) hue-rotate(258deg) brightness(88%) contrast(100%);
}
.filter-purple {
  filter: var(--ztn-filter-purple);
}

.bg-gray-100 {
  background-color: hsl(231, 33%, 96%);
}

.inverseColors {
  background-color: var(--ztn-color-primary);
  color: var(--ztn-color-bg-secondary);
}

.bgPrimary {
  background-color: var(--ztn-color-bg-secondary);
}

.bgDarker {
  background-color: #1122;
}

.bgNone,
.bgNone:hover {
  background-color: transparent;
}
