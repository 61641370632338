.card {
  margin: 9rem auto 0 auto;
}

.prompt-text{
  color: var(--ztn-grey-text,#827895);
  font-weight: 500;
  font-size: 0.9rem;
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 2rem;
  line-height: 20px;
}

.input-text {
  color: var(--ztn-grey-text,#827895);
  font-weight: 500;
  font-size: 1rem;
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 0.9rem;
}

.form__email,
.form__password,
.actions__row1 {
  margin-bottom: 3rem;
}

.input__email {
  font-size: 1rem;
  padding: 1rem 1.2rem;
  height: 56px;
}

.actions__row1 {
  margin-top: 3rem;
}

.hud {
  margin-bottom: 2rem;
}
.instruction {
  margin-bottom: 2rem;
  color: var(--ztn-grey-text,#827895);
}

.link {
  color: var(--ztn-theme-primary);
  font-weight: bold;
  margin-bottom: 2.7rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.link__arrow {
  width: 13px;
  height: 16px;
}

.resendEmail {
  margin: 3.5rem 0;
}
