:root {
  --box-shadow-light: 6px 6px 54px rgba(0, 0, 0, 0.05);
  --box-shadow-rg: 6px 6px 20px rgba(84,80,89,0.1);
}

.box-shadow-light {
  box-shadow: var(--box-shadow-light);
}
.box-shadow-rg {
  box-shadow: var(--box-shadow-rg);
}

.dropshadowInner {
  box-shadow: inset 0px 0px 10px 0 #115f ;
}
