.container {
	max-width: 750px;
	margin-left: 12rem;
	margin-top: 6.75rem;
}

.link, .title {
	color: var(--ztn-theme-primary);
	font-weight: bold;
}

.link {
	margin-bottom: 2.7rem;
	display: flex;
	align-items: center;
	gap: 8px;
}

.title {
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 2.25rem;
}

.terms {
	font-family: 'Roboto', sans-serif;
	font-size: 13px;
	line-height: 20px;
	color: #827895;
}

.link__arrow {
	width: 13px;
	height: 16px;
}
