.menu {
    position: sticky;
    top: 61px;
}

.link,
.activeLink,
.linkDark,
.activeLinkDark {
    font-size: 1.2rem;
    color: var(--ztn-purple);
    text-decoration: none;
    font-size: 9px;
    text-align: center;
    font-weight: 500;
}

.link svg,
.activeLink svg,
.linkDark svg,
.activeLinkDark svg {
    font-size: 2rem;
}

.listItem {
    width: 100%;
    height: 50px;
}

.activeLink,
.activeLinkDark {
    background: var(--ztn-light-purple);
    position: relative;
}


.listItem p {
    padding: 2px 0;
    font-size: 14px;
    line-height: 14px;
    justify-self: left;
}

.listItem svg {
    width: 24px;
    height: 24px;
}

.linkStyles {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr;
    align-items: center;
    height: 100%;
}

.linksContainer {
    scrollbar-width: thin;
}

.linksContainer::-webkit-scrollbar {
    width: 4px;
    height: 8px;
}

.linksContainer::-webkit-scrollbar-thumb {
    background-color: #9B9B9B80;
}

.linksContainer::-webkit-scrollbar-thumb:hover {
    background-color: #A6A6A6;
}

.linksContainer::-webkit-scrollbar-thumb:active {
    background-color: #606060;
}

.linksContainer::-webkit-scrollbar-track {
    background-color: #F0F0F0;
}

@media(min-width: 640px) {
    .linksContainer {
        overflow-y: auto;
    }
}
@media(max-width: 639px) {
    .linkStyles {
        grid-template-columns: 1fr;
        justify-items: center;
        padding: 0.5rem;
        height: 100%;
        width: 100%;
    }
    .listItem {
        height: 100%;
        min-width: 70px;
        width: unset
    }
    .listItem svg {
        height: unset;
        width: unset;
    }

    .listItem p {
        padding: 2px 0;
        font-size: 9px;
        text-align: center;
        font-weight: 500;
        justify-self: center;
        white-space: nowrap
    }

    .linksContainer {
        overflow-x: scroll;
        width: 100%;
    }

    .contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        border-left: 1px solid var(--ztn-purple-grey);
        border-bottom: 8px solid #F0F0F0;
    }
}
