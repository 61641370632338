.icon-xxs {
  width: 12px;
  height: 12px;
}
.icon-xs {
  width: 16px;
  height: 16px;
}
.icon-sm {
  width: 24px;
  height: 24px;
}
.icon-rg {
  width: 32px;
  height: 32px
}
.icon-lg {
  width: 40px;
  height: 40px
}
